import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';

const MediaKitPage = ({ location, data }) => {
  const logos = [
    'logo-standard',
    'logo-standard-vertical',
    'logo-invert',
    'logo-invert-vertical',
    'logo-white',
    'logo-white-vertical',
    'logo-dark',
    'logo-dark-vertical',
  ];
  const logoSrcs = logos.map((logo) => {
    return {
      standard: logo + '.png',
      '4x': logo + '-4x.png',
      svg: logo + '.svg',
    };
  });
  return (
    <Layout>
      <SEO title="GrowSF Media Kit" description="GrowSF Media Kit" pathname={location.pathname} />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-5 sm:px-6 lg:px-8">
          <div className="text-center py-12">
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-6xl">
              GrowSF Media Kit
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-16">
            <h2 className="text-3xl text-center font-extrabold tracking-tight sm:text-4xl">
              Standard logos
            </h2>
            <table className="table-auto min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left" scope="col">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left" scope="col">
                    Standard png
                  </th>
                  <th className="px-6 py-3 text-left" scope="col">
                    4x png
                  </th>
                  <th className="px-6 py-3 text-left" scope="col">
                    svg
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {logos.map((logo) => {
                  return (
                    <tr
                      className={
                        logo.includes('white')
                          ? 'bg-gray-700 text-white'
                          : logo.includes('invert')
                          ? 'bg-gray-300'
                          : 'bg-white'
                      }
                    >
                      <td className="px-6 py-4 whitespace-nowrap">{logo}</td>
                      <td className="px-6 py-4">
                        <Link to={'/media-kit/' + logo + '.png'}>
                          <img src={'/media-kit/' + logo + '.png'} width="200" />
                        </Link>
                      </td>

                      <td className="px-6 py-4">
                        <Link to={'/media-kit/' + logo + '-4x.png'}>
                          <img src={'/media-kit/' + logo + '-4x.png'} width="200" />
                        </Link>
                      </td>
                      <td className="px-6 py-4">
                        <Link to={'/media-kit/' + logo + '.svg'}>
                          <img src={'/media-kit/' + logo + '.svg'} width="200" />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="bg-white mx-auto max-w-4xl">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-16">
            <h2 className="text-3xl text-center font-extrabold tracking-tight sm:text-4xl">
              Icons
            </h2>
            <table className="table-auto min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left" scope="col">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left" scope="col">
                    Standard png
                  </th>
                  <th className="px-6 py-3 text-left" scope="col">
                    svg
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap">Default</td>
                  <td className="px-6 py-4">
                    <Link to={'/media-kit/icon.png'}>
                      <img src={'/media-kit/icon.png'} width="100" />
                    </Link>
                  </td>
                  <td className="px-6 py-4">
                    <Link to={'/media-kit/icon.svg'}>
                      <img src={'/media-kit/icon.svg'} width="100" />
                    </Link>
                  </td>
                </tr>
                <tr className="bg-gray-700 text-white">
                  <td className="px-6 py-4 whitespace-nowrap">Inverted</td>
                  <td className="px-6 py-4">
                    <Link to={'/media-kit/icon-invert.png'}>
                      <img src={'/media-kit/icon-invert.png'} width="100" />
                    </Link>
                  </td>
                  <td className="px-6 py-4">
                    <Link to={'/media-kit/icon-invert.svg'}>
                      <img src={'/media-kit/icon-invert.svg'} width="100" />
                    </Link>
                  </td>
                </tr>
                <tr className="bg-black text-white">
                  <td className="px-6 py-4 whitespace-nowrap">Black bg</td>
                  <td className="px-6 py-4">
                    <Link to={'/media-kit/icon-invert-black-bg.png'}>
                      <img src={'/media-kit/icon-invert-black-bg.png'} width="100" />
                    </Link>
                  </td>
                  <td>n/a</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap">White bg</td>
                  <td className="px-6 py-4">
                    <Link to={'/media-kit/icon-white-bg.png'}>
                      <img src={'/media-kit/icon-white-bg.png'} width="100" />
                    </Link>
                  </td>
                  <td>n/a</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MediaKitPage;
